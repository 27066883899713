/* Member List, Vue Component */
<template>
  <v-card id="member-list">
    <v-card-title>Find Member To Book-In Item</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">

        <div class="d-flex align-center pb-5 flex-wrap w-100">

        <v-text-field v-model="search.Reference"
                      single-line
                      dense
                      outlined
                      hide-details
                      placeholder="Search Reference"
                      class="mr-4"></v-text-field>

        <v-text-field v-model="search.FullName"
                      single-line
                      dense
                      outlined
                      hide-details
                      placeholder="Search Name"
                      class="mr-4"></v-text-field>

        <v-spacer></v-spacer>

        <v-text-field v-model="search.all"
                      single-line
                      dense
                      outlined
                      hide-details
                      placeholder="Search All"
                      class="mr-4"></v-text-field>

      </div>

    </v-card-text>

    <!-- table -->
    <v-data-table v-model="selectedItems"
                  :headers="tableColumns"
                  :items="items"
                  :options.sync="options"
                  :server-items-length="totalItems"
                  :loading="loading"
                  :footer-props="footerProps"
                  class="text-no-wrap">

      <template #[`item.Reference`]="{item}">
        <strong>{{ item.Reference }}</strong>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-left">
        <v-btn color="success" small
        :to="{ name: 'order-bookitem-create', params: { id: item.Id } }">Create Order</v-btn>
        </div>
      </template>


    </v-data-table>
  </v-card>
</template>

<script>

  import store from '@/store'

  import {
    mdiPlus,
    mdiDeleteOutline,
    mdiEyeOutline,
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
  } from '@mdi/js'

  import { ref, watch, computed } from '@vue/composition-api'
  import * as Validators from '@/datahelpers/validation'
  import * as Display from '@/datahelpers/displayTypes'
  import ActionsDropdown from '@/views/helpers/ActionsDropdown'
  import CountrySelect from '@/views/country/CountrySelect'

  export default {
    components: {
      ActionsDropdown,
      CountrySelect
    },
    setup() {

      // define cols
      const tableColumns = [
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
        { text: 'Reference', value: 'Reference' },
        { text: 'Name', value: 'FullName' },
        { text: 'Email', value: 'Email' },
        { text: 'Country Name', value: 'CountryId_Name' },
      ];

      // threee params for fetching
      const search = ref({});
      const filter = ref({
        Enabled: true
      });
      const options = ref({
        sortBy: ['Reference'],
        sortDesc: [false],
        itemsPerPage: 20
      });
      const footerProps = {
        'items-per-page-options': [10, 20, 50, 100, -1]
      };

      // items
      const items = computed(() => store.state.app.Members || []);
      const totalItems = computed(() => store.state.app.MembersTotal || 0);
      const selectedItems = ref([]);
      const loading = ref(false);

      // method for data loading via vuex
      const fetchData = () => {
        store
          .dispatch('app/fetchMembers', {
            search: search.value,
            options: options.value,
            filter: filter.value,
          })
          .then(response => {
            loading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }

      watch([search, filter, options], () => {

        // start loading
        loading.value = true
        fetchData()
      }, { deep: true });


      // const createOrder = (Id) => {
      //   console.log("createOrder", Id);

      //   // create a pay-on-arrival order on server and redirect to that id
      //   store
      //     .dispatch('app/createOrder', {
      //       entity: {
      //         MemberId: Id
      //       }
      //     })
      //     .then(response => {
      //       loading.value = false
      //     })
      //     .catch(error => {
      //       console.log(error)
      //     });
      // }

      return {
        tableColumns,
        search,
        filter,
        options,
        totalItems,
        items,
        loading,
        selectedItems,
        footerProps,
        Validators,
        Display,
        icons: {
          mdiPlus,
          //mdiDeleteOutline,
          //mdiEyeOutline,
          //mdiPencilOutline,
        },
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
